import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';

import { AlertsService, DataService, JwtService, UserService } from '../services';
import { catchError, switchMap } from 'rxjs/operators';
import { VersionService } from '../services/version.service';
const preconditionRequiredStatusCode = 428;

@Injectable({
  providedIn: 'root'
})
export class HttpTokenInterceptor implements HttpInterceptor {
  private isReloading = false;
  constructor(private jwtService: JwtService,
    private _dataService: DataService,
    private versionService: VersionService,
    private userService: UserService,
    private alertsService: AlertsService
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const appVersion = this.versionService.getVersion();
    const headersConfig: any = {
      //'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'App-Version': appVersion
    };

    const token = this._dataService.vaccineContext.getToken();
    if (token) {
      headersConfig['Authorization'] = `Bearer ${token}`;
      const request = req.clone({ setHeaders: headersConfig });
      return next.handle(request).pipe(
        catchError(error => this.handleError(error))
      );
    }

    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request).pipe(
      catchError(error => this.handleError(error))
    );
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === preconditionRequiredStatusCode) {
      console.log("428 caught.." + error.message);
      return from(this.alertsService.showErrorMessage(
        'The browser will be refreshed and you will be logged off to update the application. Please Re-login and continue.',
        'New Application version available'
      )).pipe(
        switchMap((resp: { isConfirmed }) => {
          if (resp && resp.isConfirmed) {
            this.userService.logOff();
            localStorage.setItem('AppVersion','0.0');
            window.location.reload();
          }
          return throwError(error); 
        })
      );
    }    
    return throwError(error);
  }
}
