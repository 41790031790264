import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VersionService {  
  isVersionLoaded : boolean;

  constructor(private http: HttpClient) {}

  // Fetch the app version from version.json and update the localStorage
  loadAppVersion(): Observable<void> {
    return new Observable<void>((observer) => {
      this.http.get<{ AppVersion: string }>('assets/version.json').pipe(
        catchError((error) => {
          console.error('Error loading version file:', error);          
          return of({ AppVersion: '' }); // Return empty default value
        })
      ).subscribe((data) => {
        if (data?.AppVersion) {          
          localStorage.setItem('AppVersion',data.AppVersion);
          this.isVersionLoaded = true; // Set flag as true to indicate version is loaded
        } else {
          localStorage.setItem('AppVersion','0.0');
        }
        observer.next();
        observer.complete();
      });
    });
  }
  
  getVersion(): string {
    return localStorage.getItem('AppVersion'); // Get the current version
  }
}
